@use '@angular/material' as mat;
@use 'sass:map';
// @import '@angular/material/theming';
@import './variables';
@import './typography';
@include mat.core();

$ressourcesetvous-primary: mat.m2-define-palette($primary);
$ressourcesetvous-accent: mat.m2-define-palette($accent, A200, A100, A400);
$ressourcesetvous-warn: mat.m2-define-palette($warn);

$ressourcesetvous-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $ressourcesetvous-primary,
      accent: $ressourcesetvous-accent,
      warn: $ressourcesetvous-warn
    )
  )
);
@include mat.all-component-themes($ressourcesetvous-theme);

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --warning-size: 0px;
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

section,
main,
header {
  padding-block-start: 3rem;
  padding-block-end: 3rem;
  position: relative;
}

section,
main {
  background-color: $light-color;

  .primaire {
    color: map-get($map: $primary, $key: 500);
  }
  .accent {
    color: map-get($accent, 700);
  }
  .rouge {
    color: map-get($warn, 500);
  }
}

hr {
  max-width: 50px;
  border-radius: 2px;
  border: solid 3px map-get($map: $primary, $key: 500);
  background-color: map-get($map: $primary, $key: 500);
  margin: 1rem auto;
}

.section-primary {
  background-color: map-get($map: $primary, $key: 700);

  & hr {
    border-color: $light-color !important;
    background-color: $light-color !important;
  }

  & .text a {
    color: $dark-color !important;
    &:hover {
      color: lighten($color: $dark-color, $amount: 0.2) !important;
    }
  }
  .primaire {
    color: lighten(map-get($map: $primary, $key: 300), 30%);
  }
  .accent {
    color: map-get($accent, 300);
  }
  .rouge {
    color: map-get($warn, 400);
  }
}

.section-dark {
  background-color: $dark-color;

  & hr {
    border-color: $light-color !important;
    background-color: $light-color !important;
  }

  & input,
  & button {
    color: $dark-color;
  }

  .primaire {
    color: map-get($map: $primary, $key: 200);
  }
  .accent {
    color: map-get($accent, 300);
  }
  .rouge {
    color: map-get($warn, 400);
  }
}

.reset {
  all: unset;
}

ul,
ol {
  padding-inline-start: 1.5rem;
}

.collect-form {
  & .mat-vertical-content-container {
    margin-left: 0 !important;
  }
  & .mat-vertical-content {
    padding: 24px;
  }
}

.input-file {
  &.mat-form-field-appearance-fill.mat-form-field-disabled {
    & .mat-form-field-flex {
      background-color: rgba(0, 0, 0, 0.04);
    }
    & .mat-form-field-underline::before {
      background-color: rgba(0, 0, 0, 0.42);
    }
    & .mat-form-field-label {
      color: rgba(0, 0, 0, 0.6);
    }
    & .mat-input-element:disabled {
      color: $dark-color;
    }
  }
}

.w-100 {
  width: 100%;
}

.shimmer {
  $base: lighten(
    map-get(
      $map: $primary,
      $key: 50
    ),
    10%
  );
  background-color: $base;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    $base 0%,
    darken($base, 10%) 20%,
    $base 40%,
    $base 100%
  );
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  animation: placeholderShimmer 1.2s forwards infinite linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -340px 0;
  }

  100% {
    background-position: 340px 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -50rem top; /*50px*/
  }
  70% {
    background-position: 12.5rem top; /*200px*/
  }
  100% {
    background-position: 12.5rem top; /*200px*/
  }
}

@media screen and (max-width: 768px) {
  section,
  main,
  header {
    padding-block-start: 2rem;
    padding-block-end: 2rem;
  }
}

@media screen and (max-width: 540px) {
  section,
  main,
  header {
    padding-block-start: 1.6rem;
    padding-block-end: 1.6rem;
  }
}

@media screen and (max-width: 300px) {
  section,
  main,
  header {
    padding-block-start: 1rem;
    padding-block-end: 1rem;
  }
}
