@font-face {
  font-family: $title-font;
  font-weight: bold;
  src: url('./assets/fonts/Gotham-Bold.otf') format('opentype');
}

@font-face {
  font-family: $title-font;
  font-weight: light;
  src: url('./assets/fonts/Gotham-Light.otf') format('opentype');
}
@font-face {
  font-family: $title-font;
  font-weight: 500;
  src: url('./assets/fonts/GothamMedium.ttf') format('truetype');
}


@font-face {
  font-family: $body-font;
  src: url('./assets/fonts/Cronos.ttf') format('truetype');
}

::selection {
  background-color: $dark-color;
  color: $light-color;
}

h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase;
  font-family: $title-font, 'Courier New', Courier, monospace;
  text-align: center;
  font-weight: 500;
  line-height: 120%;
}

body,
span,
p,
input,
div,
a,
img, .paragraph-styled {
  font-family: $body-font, Roboto, 'Helvetica Neue', sans-serif;
}

h2,
h3,
h4,
h4,
h6 {
  width: clamp(200px, 80%, 1250px);
  margin: auto;
}

h1 {
  width: 100%;
  font-size: 2rem;
  margin-bottom: .8rem;
  // line-height: 2.6em;
}

h2 {
  font-size: 1.7rem;
  // line-height: 2.4em;
}

h3 {
  font-size: 1.6rem;
  // line-height: 2.2em;
}

h4 {
  font-size: 1.5rem;
  // line-height: 2em;
}

p, .paragraph-styled {
  width: clamp(300px, 80%, 1250px);
  margin: auto;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.4;
  margin-bottom: 2rem;
}

a {
  color: map-get($map: $primary, $key: 600);
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: map-get($map: $primary, $key: 800);
  }
}

ol {
  text-align: start;
  list-style-type: decimal;
  width: clamp(300px, 80%, 1250px);
  margin: auto;
  padding-left: 30px;
}

section,
main,
header {
  text-align: center;
}

section,
main {
  background-color: $light-color;
}

.section-dark,
.section-primary {
  color: $light-color;
  text-decoration: none;
}

.section-dark *::selection {
  background-color: opacify($color: $light-color, $amount: 0.6);
  color: $dark-color;
}

.justify {
  text-align: justify;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

@media screen and (max-width: 540px) {
  h1 {
    font-size: 1.7rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.35rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  p,
  a {
    text-align: unset;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 400px) {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.35rem;
  }
  h3 {
    font-size: 1.23rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  p,
  a,.paragraph-styled {
    font-size: 1rem;
  }
}

@media screen and (max-width: 300px) {
  h1 {
    font-size: 1.2rem;
  }
  h2 {
    font-size: 1.12rem;
  }
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 0.87rem;
  }
  p,
  a,.paragraph-styled {
    font-size: 0.79rem;
  }
}
