$light-color: hsl(220, 12%, 95%);
$dark-color: hsl(210, 11%, 15%);

$width: 368px;
$height: 280px;

$navbar-height: 75px;

$title-font: 'Gotham';
$body-font: 'Cronos';

$primary: (
  50: hsl(183, 47%, 75%),
  100: hsl(183, 47%, 72%),
  200: hsl(183, 47%, 66%),
  300: hsl(183, 47%, 60%),
  400: hsl(183, 47%, 56%),
  500: hsl(183, 47%, 48%),
  600: hsl(183, 47%, 42%),
  700: hsl(183, 47%, 36%),
  800: hsl(183, 47%, 30%),
  900: hsl(183, 47%, 24%),
  A100: hsl(183, 35%, 72%),
  A200: hsl(183, 38%, 66%),
  A400: hsl(183, 45%, 56%),
  A700: hsl(183, 64%, 36%),
  contrast: (
    50: $dark-color,
    100: $dark-color,
    200: $dark-color,
    300: $dark-color,
    400: $dark-color,
    500: $dark-color,
    600: $dark-color,
    700: $light-color,
    800: $light-color,
    900: $light-color,
    A100: $dark-color,
    A200: $dark-color,
    A400: $dark-color,
    A700: $light-color
  )
);

$warn: (
  50: hsl(11, 70%, 77%),
  100: hsl(11, 70%, 75%),
  200: hsl(11, 70%, 71%),
  300: hsl(11, 70%, 65%),
  400: hsl(11, 70%, 60%),
  500: hsl(11, 70%, 54%),
  600: hsl(11, 70%, 48%),
  700: hsl(11, 70%, 42%),
  800: hsl(11, 70%, 36%),
  900: hsl(11, 70%, 30%),
  A100: hsl(11, 55%, 72%),
  A200: hsl(11, 58%, 66%),
  A400: hsl(11, 65%, 56%),
  A700: hsl(11, 84%, 36%),
  contrast: (
    50: $dark-color,
    100: $dark-color,
    200: $dark-color,
    300: $dark-color,
    400: $dark-color,
    500: $dark-color,
    600: $dark-color,
    700: $light-color,
    800: $light-color,
    900: $light-color,
    A100: $dark-color,
    A200: $dark-color,
    A400: $dark-color,
    A700: $light-color
  )
);

$accent: (
  50: hsl(61, 69%, 77%),
  100: hsl(61, 70%, 75%),
  200: hsl(61, 70%, 71%),
  300: hsl(61, 70%, 65%),
  400: hsl(61, 70%, 60%),
  500: hsl(61, 70%, 54%),
  600: hsl(61, 70%, 48%),
  700: hsl(61, 70%, 42%),
  800: hsl(61, 70%, 36%),
  900: hsl(61, 70%, 30%),
  A100: hsl(61, 55%, 72%),
  A200: hsl(61, 58%, 66%),
  A400: hsl(61, 65%, 56%),
  A700: hsl(61, 84%, 36%),
  contrast: (
    50: $dark-color,
    100: $dark-color,
    200: $dark-color,
    300: $dark-color,
    400: $dark-color,
    500: $dark-color,
    600: $dark-color,
    700: $dark-color,
    800: $dark-color,
    900: $light-color,
    A100: $dark-color,
    A200: $dark-color,
    A400: $dark-color,
    A700: $dark-color
  )
);
